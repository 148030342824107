import * as React from "react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useChangeTracker } from "@app/quest/edit/useChangeTracker";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import { usePromise } from "@app/util/usePromise";
import { updateQuests } from "@app/util/cacheMod";
import styled from "styled-components/native";
import Button from "@app/components/questkit/button";
import {
  StartTriggerCard,
  StartTriggerSummary,
} from "@app/quest/start/StartTriggerCard";
import { sentry } from "@app/util/sentry";
import { QUEST_TYPES } from "@app/quest/start/QuestTypes";
import { StartTriggerEditFieldsForType } from "@app/store/cache/questStartTriggers";
import { useAppSelector } from "@app/store";
import { selectQuestPrototypeById } from "@app/store/cache/questPrototypes";
import { useQuestViewContext } from "@app/quest/QuestViewContext";
import { startQuestPrototype } from "@app/util/client/requests/quests";
import { buildAssigneeNotificationsString } from "@app/components/snackbar/MessageBuilders";
import { getLoggedInUserId } from "@app/util/getLoggedInUserId";
import { useAppNavigation } from "@app/navigation/QMNavigator";
import { UserPickerController } from "@app/components/questkit/UserList/UserPicker";
import HeaderText from "@app/components/questkit/headerText";
import { AssigneePicker } from "@app/components/questkit/AssigneePicker";
import { selectLoggedInUserId } from "@app/store/auth";
import type { UserListEvents } from "@app/components/questkit/UserList/UserList.controller";

interface ManualStartViewProps {
  startTrigger: StartTriggerEditFieldsForType<"BASIC">;
  openStartQuestModal: () => void;
  startChangingQuestType: () => void;
  readOnly: boolean;
}

export const ManualStartView: React.FC<ManualStartViewProps> = ({
  startTrigger: startTriggerFromServer,
  startChangingQuestType,
  readOnly,
}) => {
  const { questId, questPrototypeId } = useQuestViewContext([
    "MANAGE",
    "PREVIEW",
  ]);
  const questName = useAppSelector(
    (state) => selectQuestPrototypeById(state, questPrototypeId)?.name
  );

  const { useValueWithChanges } = useChangeTracker(startTriggerFromServer);
  const startTrigger = useValueWithChanges();

  const snackbar = useContext(SnackbarContext);
  const navigation = useAppNavigation();

  // const { execute: save, isLoading: isSaving } = usePromise(async () => {
  //   const changeSet = getChangeSet();
  //   const changedStartTrigger = changeSet.valueWithChanges;
  //
  //   if (changeSet.hasUnsavedChanges) {
  //     changeSet.markPending();
  //     return await updateQuestStartTrigger(questPrototypeId, startTrigger.id, {
  //       enabled: changedStartTrigger.enabled,
  //     })
  //       .then((response) => {
  //         changeSet.markSaved();
  //         void mutate(["get", `/quests/${questPrototypeId}`]);
  //         void updateQuests();
  //         return response;
  //       })
  //       .catch((e) => {
  //         changeSet.rollbackChanges(changeSet.changesByStatus.pending);
  //         console.log(e);
  //         snackbar.sendMessage(
  //           `We unfortunately couldn't save. Please try again later.`,
  //           SnackbarSeverity.WARNING
  //         );
  //       });
  //   }
  // });

  const assigneePickerRef = useRef<UserPickerController>(null);
  const [questStarted, setQuestStarted] = useState(false);

  const { execute: onStartQuest, isLoading: startQuestLoading } = usePromise(
    async () => {
      const cleanedQuestName = questName.trim();
      if (!cleanedQuestName) {
        snackbar.sendMessage(
          `Please give your Quest a name.`,
          SnackbarSeverity.WARNING
        );
        return;
      }

      const users = await assigneePickerRef.current!.flush().catch((e) => {
        snackbar.sendMessage(
          `Couldn't set Assignees on Quest Run. Please try again.`,
          SnackbarSeverity.WARNING
        );
        sentry.captureException(e, {
          extra: { message: "Failed to flush assignee picker" },
        });
        throw e;
      });

      return startQuestPrototype(startTrigger.questPrototypeId, {
        assignees: users.map((user) => ({ userId: user.id })),
        // ...(cleanedQuestName !== data?.name
        //   ? {
        //       name: cleanedQuestName,
        //     }
        //   : {}),
      })
        .then((startQuestData) => {
          setQuestStarted(true);
          void updateQuests();

          const assignments = startQuestData.assignments;

          const inviteNotificationsString =
            buildAssigneeNotificationsString(assignments);

          // closeStartQuestModal();

          navigation.navigate("Quest", {
            questId: questId,
            screen: "QuestRuns",
          });

          setTimeout(() => {
            if (assignments.length === 0 && startQuestData.sharedInstance?.id) {
              navigation.navigate("AdminQuestRun", {
                templateId: startQuestData.quest.id,
                questInstanceId: startQuestData.sharedInstance.id,
              });
              snackbar.sendMessage(`Run created.`);
            } else if (
              assignments.length === 1 &&
              startQuestData.sharedInstance?.id
            ) {
              const isAssignedToUser =
                assignments[0].assignee.id === getLoggedInUserId(true);

              if (isAssignedToUser) {
                navigation.navigate("QuestInstance", {
                  id: assignments[0].formInstance!.id!,
                });

                snackbar.sendMessage(`Run created.`);
              } else {
                navigation.navigate("AdminQuestRun", {
                  templateId: startQuestData.quest.id,
                  questInstanceId: startQuestData.sharedInstance.id,
                });
                snackbar.sendMessage(
                  `Run created.${inviteNotificationsString}`
                );
              }
            } else {
              navigation.navigate("Quest", {
                questId: startQuestData.quest.id,
                screen: "QuestRuns",
                params: {
                  filters: {
                    status: ["OPEN"],
                  },
                },
              });
              snackbar.sendMessage(`Runs created.${inviteNotificationsString}`);
            }

            assigneePickerRef.current?.reset();
            setQuestStarted(false);
          }, 0);
        })
        .catch((e) => {
          console.log("Failed to start Quest Run", e);
          snackbar.sendMessage(
            `Couldn't start Quest Run.`,
            SnackbarSeverity.WARNING
          );
          sentry.captureException(e, {
            extra: {
              questId,
              questPrototypeId: startTrigger.questPrototypeId,
            },
          });
        });
    }
  );
  const [isAssignedToOthers, setisAssignedToOthers] = useState(false);
  const [hasTextInAssigneeInput, setHasTextInAssigneeInput] = useState(false);
  const [isLookingUpUsers, setIsLookingUpUsers] = useState(false);
  const isSendingQuestToOthers =
    isAssignedToOthers || hasTextInAssigneeInput || isLookingUpUsers;

  useEffect(() => {
    const listener = ({
      lookupCompletedPromise,
    }: UserListEvents["lookupUsers"]) => {
      setIsLookingUpUsers(true);
      void lookupCompletedPromise.finally(() => setIsLookingUpUsers(false));
    };

    const assigneePicker = assigneePickerRef.current;
    assigneePicker?.on("lookupUsers", listener);

    return () => {
      assigneePicker?.off("lookupUsers", listener);
    };
  }, []);

  const loggedInUserId = useAppSelector(selectLoggedInUserId);
  const onAssigneeChange = useCallback(
    (userIds: string[]) => {
      if (
        userIds.length === 0 ||
        (userIds.length === 1 && userIds[0] === loggedInUserId)
      ) {
        setisAssignedToOthers(false);
      } else {
        setisAssignedToOthers(true);
      }
    },
    [loggedInUserId]
  );
  const onAssigneeInputTextChange = useCallback((text: string) => {
    setHasTextInAssigneeInput(!!text);
  }, []);

  if (readOnly) {
    return (
      <StartTriggerSummary
        text={"Starts Manually"}
        icon={QUEST_TYPES["BASIC"].icon}
      />
    );
  } else {
    return (
      <StartTriggerCard
        title={QUEST_TYPES["BASIC"].name}
        icon={QUEST_TYPES["BASIC"].icon}
        startChangingQuestType={startChangingQuestType}
      >
        <HeaderText title="Assignees" />
        <Section>
          <AssigneePicker
            startConfigurationId={startTrigger.startConfigurationId!}
            noInitialAssignees={true}
            ref={assigneePickerRef}
            onChangeAssignees={onAssigneeChange}
            onInputTextChange={onAssigneeInputTextChange}
          />
        </Section>
        <Button
          onPress={onStartQuest}
          title={isSendingQuestToOthers ? "Run & Send Quest" : "Run Quest"}
          icon={isSendingQuestToOthers ? "send" : "play"}
          testID="start-manual-quest-run-button"
          buttonType="primary"
          loading={startQuestLoading}
          success={questStarted}
        />
      </StartTriggerCard>
    );
  }
};

const Section = styled.View`
  margin-bottom: 20px;
`;
