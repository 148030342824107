import React, { Dispatch, SetStateAction, useContext } from "react";
import {
  QuestInstanceDetail,
  QuestInstanceStatus,
} from "@questmate/openapi-spec";
import { PromiseTracker } from "@app/util/PromiseTracker";
import { EditableQuestPrototypeDetails } from "@app/store/cache/questPrototypes";
import { UseChangeTrackerResult } from "@app/quest/edit/useChangeTracker";
import { ValidationError } from "@questmate/common";

export type SaveSkippedResult = {
  success: true;
  result: "SKIPPED";
  reason: string;
};
export type SaveSuccessResult = {
  success: true;
  result: "SAVED";
};
export type SaveFailureResult = {
  success: false;
  result: "SAVE_FAILED";
  error: unknown;
};
export type NotValidResult = {
  success: false;
  result: "NOT_VALID";
  errors: ValidationError[];
};

export type SaveResult =
  | SaveSkippedResult
  | SaveSuccessResult
  | SaveFailureResult
  | NotValidResult;

export interface ManageQuestViewContext {
  viewContext: "MANAGE";
  questId: string;
  save: (skipValidations?: boolean) => Promise<SaveResult>;
  isSaving: boolean;

  addChange: UseChangeTrackerResult<EditableQuestPrototypeDetails>["addChange"];
  useQuestPrototypeWithChanges: UseChangeTrackerResult<EditableQuestPrototypeDetails>["useValueWithChanges"];

  isOwner: boolean;
  questPrototypeId: string;
  touchedMap: Record<string, boolean>;
  setFieldTouched: (fieldName: string, touchedState: boolean) => void;
  markAllFieldsAsTouched: () => void;
  advancedMode: boolean;
  useScopedValidationErrors: (pathScope: string[]) => ValidationError[];

  /**
   * @deprecated
   */
  errorMap: Record<string, string[]>;
  /**
   * @deprecated
   */
  setValidationContext: Dispatch<
    SetStateAction<{
      rewards: Record<string, unknown>;
    }>
  >;
}

interface RunQuestViewContext {
  viewContext: "RUN";
  /**
   * undefined when not yet loaded
   */
  questId: string | undefined;
  questInstanceId: string;
  /**
   * undefined when not yet loaded
   */
  status: QuestInstanceStatus | undefined;
  roles: QuestInstanceDetail["requestingUser"]["instanceRoles"];
  publicQuestSessionToken?: string;

  recentlySubmittedByUser: boolean;
  markRecentlySubmittedByUser: () => void;
  preSubmitPromiseTracker: PromiseTracker;
}

// May make sense to split this and remove fields that are not relevant to preview.
type PreviewQuestViewContext = Omit<ManageQuestViewContext, "viewContext"> & {
  viewContext: "PREVIEW";
};

type QuestViewContext =
  | ManageQuestViewContext
  | RunQuestViewContext
  | PreviewQuestViewContext;

export const QuestViewContext = React.createContext<QuestViewContext>({
  viewContext: "MANAGE",
  questId: "",
  save: () =>
    Promise.resolve({
      success: false,
      result: "SAVE_FAILED",
      error: new Error("QuestViewContext used outside of a provider!"),
    }),
  isSaving: false,
  isOwner: false,
  questPrototypeId: "",
  errorMap: {},
  touchedMap: {},
  setFieldTouched: () => undefined,
  markAllFieldsAsTouched: () => undefined,
  addChange: () => undefined,
  useQuestPrototypeWithChanges: <T,>() => undefined as T,
  useScopedValidationErrors: () => [],
  setValidationContext: () => undefined,
  advancedMode: false,
});

export function isViewContext<T extends QuestViewContext["viewContext"]>(
  questViewContext: QuestViewContext,
  viewContext: T
): questViewContext is QuestContextForEditMode<T> {
  return questViewContext.viewContext === viewContext;
}

type QuestContextForEditMode<T extends QuestViewContext["viewContext"]> =
  T extends undefined
    ? QuestViewContext
    : T extends "MANAGE"
    ? ManageQuestViewContext
    : T extends "RUN"
    ? RunQuestViewContext
    : T extends "PREVIEW"
    ? PreviewQuestViewContext
    : never;
export const useQuestViewContext = <T extends QuestViewContext["viewContext"]>(
  expectedViewContexts?: T[]
): QuestContextForEditMode<T> => {
  const questViewContext = useContext(QuestViewContext);
  if (!questViewContext) {
    throw new Error(
      "Attempted to use QuestViewContext outside of a QuestViewContext Provider"
    );
  }
  if (
    Array.isArray(expectedViewContexts) &&
    !expectedViewContexts.includes(questViewContext.viewContext as T)
  ) {
    throw new Error(
      `Expected view context${
        expectedViewContexts.length > 1 ? "s" : ""
      } '${expectedViewContexts.join(
        ", "
      )}' did not match actual view context '${questViewContext.viewContext}'`
    );
  }
  return questViewContext as QuestContextForEditMode<T>;
};
