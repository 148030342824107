import React, { useMemo } from "react";

import { ItemBaseProps, ItemContainerWrapper } from "../itemContainer";
import {
  isViewContext,
  useQuestViewContext,
} from "@app/quest/QuestViewContext";
import { CustomItemV1ManageView } from "@app/components/item/components/custom/v1/CustomItemV1ManageView";
import { CustomItemV2ManageView } from "@app/components/item/components/custom/v2/CustomItemV2ManageView";
import { CustomItemV1FilloutView } from "@app/components/item/components/custom/v1/CustomItemV1FilloutView";
import { CustomItemV2RunView } from "@app/components/item/components/custom/v2/CustomItemV2RunView";
import { ItemDetail } from "@questmate/openapi-spec";
import { parseRequestedAppScopes } from "@questmate/questscript";
import { Text } from "@app/components/questkit/text";
import { ClickableAppLabel } from "@app/components/item/components/custom/components/ClickableAppLabel";
import styled from "styled-components/native";
import { Portal, PortalHost } from "@gorhom/portal";
import { CheckboxToggle } from "@app/components/item/components/checkbox";

export const CustomItem: React.FC<ItemBaseProps> = (props) => {
  const questContext = useQuestViewContext();

  const inlineNodePortalName = `custom-item-inline-node-${props.item.prototype.id}`;
  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={<PortalHost name={inlineNodePortalName} />}
      blockNode={
        // use questContext to determine editMode, since preview in edit mode is still considered editMode for questContext
        questContext.viewContext === "PREVIEW" ? (
          <CustomItemV2PreviewView
            script={(props.item.prototype as ItemDetail).customScript || ""}
          />
        ) : isViewContext(questContext, "MANAGE") ? (
          <CustomItemV1ManageView
            item={props.item}
            onItemChange={props.onItemChange}
            viewMode={props.editMode ? "EDIT" : "READ_ONLY"}
            inlineNodePortalName={inlineNodePortalName}
          />
        ) : (
          <CustomItemV1FilloutView {...props} />
        )
      }
    />
  );
};
export const CustomItemV2: React.FC<ItemBaseProps> = (props) => {
  const questContext = useQuestViewContext();

  const inlineNodePortalName = `custom-item-inline-node-${
    props.item.prototype.id
  }-${props.editMode ? "edit" : "read-only"}`;
  return (
    <ItemContainerWrapper
      {...props}
      inlineNode={<PortalHost name={inlineNodePortalName} />}
      blockNode={
        questContext.viewContext === "PREVIEW" ? (
          <CustomItemV2PreviewView
            script={(props.item.prototype as ItemDetail).customScript || ""}
          />
        ) : questContext.viewContext === "MANAGE" ? (
          <CustomItemV2ManageView
            item={props.item}
            onItemChange={props.onItemChange}
            viewMode={props.editMode ? "EDIT" : "READ_ONLY"}
            inlineNodePortalName={inlineNodePortalName}
          />
        ) : (
          <CustomItemV2RunView
            {...props}
            inlineNodePortalName={inlineNodePortalName}
          />
        )
      }
    />
  );
};

type CustomItemV2PreviewViewProps = {
  script: string | null | undefined;
  isCompletionAction?: boolean;
  // Do not provide to avoid showing the inline checkbox
  inlineNodePortalName?: string;
};
export const CustomItemV2PreviewView: React.FC<
  CustomItemV2PreviewViewProps
> = ({ script, isCompletionAction = false, inlineNodePortalName }) => {
  const requestedAppIds = useMemo(() => {
    if (!script) {
      return [];
    }
    return parseRequestedAppScopes(script).map((app) => app.name);
  }, [script]);

  return (
    <>
      {inlineNodePortalName ? (
        <Portal hostName={inlineNodePortalName}>
          <CheckboxToggle readOnly={true} checked={false} />
        </Portal>
      ) : null}
      <PreviewText>
        Preview unavailable.
        {requestedAppIds.length > 0
          ? ` ${
              isCompletionAction ? "Completion action" : "Item"
            } integrates with:`
          : ""}
      </PreviewText>

      {requestedAppIds.map((appId, index) => {
        return (
          <AppLabelContainer
            key={appId}
            isLast={index === requestedAppIds.length - 1}
          >
            <ClickableAppLabel appId={appId} />
          </AppLabelContainer>
        );
      })}
    </>
  );
};

const AppLabelContainer = styled.View<{ isLast: boolean }>`
  margin-top: 8px;
  margin-bottom: ${({ isLast }) => (isLast ? "0" : "8px")};
`;
const PreviewText = styled(Text)`
  margin-bottom: 8px;
`;
