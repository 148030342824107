import React, {
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styled from "styled-components/native";
import EmailPhoneInput from "../EmailPhoneInput";
import HeaderText from "@app/components/questkit/headerText";
import Icon from "@app/components/questkit/icon";
import { store } from "@app/store";
import { selectUserById, type User } from "@app/store/cache/users";
import { PromiseTracker } from "@app/util/PromiseTracker";
import { IdentifierParseResult } from "@app/screens/login/IdentifierParser";
import {
  isUserEntry,
  UserListController,
  UserListEntry,
  UserListEventEmitter,
  UserListEvents,
} from "@app/components/questkit/UserList/UserList.controller";
import {
  UserEntryViewModel,
  useUserList,
} from "@app/components/questkit/UserList/useUserList";
import { sentry } from "@app/util/sentry";
import { type UserPickerEntryProps } from "@app/components/questkit/UserList/StandardUserEntry";
import { usePromise } from "@app/util/usePromise";
import {
  SnackbarContext,
  SnackbarSeverity,
} from "@app/components/snackbar/SnackbarContext";
import isEqual from "react-fast-compare";
import { Text } from "@app/components/questkit/text";

export interface UserPickerController {
  flush(): Promise<User[]>;
  reset: UserListController["reset"];
  remove: UserListController["remove"];
  add: UserListController["add"];
  on(...args: Parameters<UserListEventEmitter["on"]>): void;
  off(...args: Parameters<UserListEventEmitter["off"]>): void;
}

interface UserPickerProps {
  users: UserListEntry[];
  renderUserEntry: React.FC<UserPickerEntryProps>;
  title?: string;
  disabled?: boolean;
  /**
   * Hides the input field and myself if not chosen.
   */
  readOnly?: boolean;
  onChange?: (userIds: string[]) => void | Promise<unknown>;
  onInputTextChange?: (text: string) => void;
  onError?: (errorEvent: UserListEvents["error"]) => void;
}

export const UserPicker = React.forwardRef<
  UserPickerController,
  UserPickerProps
>(
  (
    {
      renderUserEntry,
      title,
      disabled: parentDisabled,
      readOnly = false,
      onChange,
      onInputTextChange,
      users: usersFromParent,
      onError,
    },
    ref
  ) => {
    const snackbar = useContext(SnackbarContext);
    const { userList, entries, waitForPendingEntries } = useUserList(
      usersFromParent,
      { alwaysIncludeLoggedInUser: !readOnly }
    );

    const {
      trackPromise,
      allTrackedPromisesAreComplete: waitForParentOnChange,
    } = useRef(new PromiseTracker()).current;
    const onChangeRef = useRef(onChange);
    onChangeRef.current = onChange;
    useEffect(() => {
      const changeListener = (event: UserListEvents["change"]) => {
        const result = onChangeRef.current?.(event.userIds);
        if (result) {
          trackPromise(result);
        }
      };
      const errorListener = onError ?? createSimpleErrorReporter(snackbar);
      userList.on("change", changeListener);
      userList.on("error", errorListener);
      return () => {
        userList.off("change", changeListener);
        userList.off("error", errorListener);
      };
    }, [onError, snackbar, trackPromise, userList]);

    const [userInput, setUserInput] = useState("");

    const deferredUserInput = useDeferredValue(userInput);
    useEffect(() => {
      onInputTextChange?.(deferredUserInput);
    }, [deferredUserInput, onInputTextChange]);

    const parsedIdentifiersRef = useRef<IdentifierParseResult[]>([]);
    const setParsedIdentifiers = useRef(
      (identifiers: IdentifierParseResult[]) =>
        (parsedIdentifiersRef.current = identifiers)
    ).current;

    const clearInputField = useCallback(() => {
      setUserInput("");
      setParsedIdentifiers([]);
    }, [setParsedIdentifiers]);
    const onSubmitEditing = useCallback(() => {
      userList.add(
        parsedIdentifiersRef.current.map((identifier) => ({ identifier }))
      );
      clearInputField();
    }, [clearInputField, userList]);

    const { execute: flush, isLoading: isFlushing } = usePromise(async () => {
      if (parsedIdentifiersRef.current.length > 0) {
        userList.add(
          parsedIdentifiersRef.current.map((identifier) => ({ identifier }))
        );
        clearInputField();
      }
      await waitForParentOnChange();
      // allow time for a render cycle in case the parent `onChange` causes a change in user picker data
      await new Promise((r) => setTimeout(r));
      await waitForPendingEntries();
    });

    useImperativeHandle(
      ref,
      () => ({
        async flush() {
          await flush();
          const state = store.getState();
          return userList.getUserIds().map((userId) => {
            let user = selectUserById(state, userId);
            if (!user) {
              // this should never happen with the flush waiting for all pending entries to be resolved
              sentry.captureMessage(
                "User unexpectedly not found in cache when flushing UserPicker!",
                { extra: { userId, entries: userList.entries } }
              );
              user = {
                id: userId,
                displayName: "UnknownUser",
              };
            }
            return user;
          });
        },
        reset: userList.reset.bind(userList),
        remove: userList.remove.bind(userList),
        add: userList.add.bind(userList),
        on(...args) {
          // avoid default behavior of returning `this` from event emitter `on` method
          userList.on(...args);
        },
        off(...args) {
          // avoid default behavior of returning `this` from event emitter `off` method
          userList.off(...args);
        },
      }),
      [flush, userList]
    );

    const disabled = Boolean(parentDisabled) || isFlushing;
    return (
      <UserPickerWrapper accessibilityHint={`List of ${title ?? "users"}`}>
        {title && (
          <HeaderText title={title} nativeID={`user-picker-${title}`} />
        )}
        {readOnly ? null : (
          <StyledUserInput
            value={userInput}
            onChangeText={setUserInput}
            allowMultiple={true}
            onChangeIdentifier={setParsedIdentifiers}
            blurOnSubmit={false}
            editable={!disabled}
            onSubmitEditing={onSubmitEditing}
            rightActionComponent={plusIcon}
            accessibilityLabelledBy={`user-picker-${title}`}
            accessibilityHint="Enter email or phone number"
          />
        )}
        {readOnly && entries.length === 0 ? <Text>None selected</Text> : null}
        {entries.map((entry) => {
          return (
            <UserPickerEntryWrapper
              key={isUserEntry(entry) ? entry.userId : entry.identifier.value}
              entry={entry}
              disabled={disabled}
              readOnly={readOnly}
              userList={userList}
              renderUserEntry={renderUserEntry}
            />
          );
        })}
      </UserPickerWrapper>
    );
  }
);
UserPicker.displayName = "UserPicker";

const createSimpleErrorReporter =
  (snackbar: SnackbarContext) => (event: UserListEvents["error"]) => {
    switch (event.reasonCode) {
      case "FAILED_TO_LOOKUP_USER": {
        const { identifier } = event;
        snackbar.sendMessage(
          `Failed to add '${identifier.value}'. Please try again later.`,
          SnackbarSeverity.WARNING
        );
        break;
      }
      case "FAILED_TO_LOOKUP_ALL_USERS": {
        const identifiers = event.identifiers;
        snackbar.sendMessage(
          `Failed to add ${
            identifiers.length === 1
              ? `'${identifiers[0].value}'`
              : `${identifiers.length} users`
          }. Please try again later.`,
          SnackbarSeverity.WARNING
        );
        break;
      }
      default: {
        // noinspection UnnecessaryLocalVariableJS
        const _exhaustiveCheck: never = event;
        return _exhaustiveCheck;
      }
    }
  };

interface UserPickerEntryWrapperProps {
  entry: UserEntryViewModel;
  disabled: boolean;
  readOnly: boolean;
  userList: UserListController;
  renderUserEntry: React.FC<UserPickerEntryProps>;
}
export const UserPickerEntryWrapper: React.FC<UserPickerEntryWrapperProps> =
  React.memo(({ entry, disabled, readOnly, userList, renderUserEntry }) => {
    const onRemove = useCallback(() => {
      userList.remove([entry]);
    }, [entry, userList]);
    const onAdd = useCallback(() => {
      userList.add([entry]);
    }, [entry, userList]);

    return renderUserEntry({
      entry,
      disabled,
      readOnly,
      onAdd,
      onRemove,
    });
  }, isEqual);
UserPickerEntryWrapper.displayName = "UserPickerEntryWrapper";

const StyledUserInput = styled(EmailPhoneInput)`
  margin-bottom: 16px;
`;

const AddUserIconContainer = styled.View`
  align-items: center;
  justify-content: center;
`;
const plusIcon = () => {
  return (
    <AddUserIconContainer testID="add-user-button">
      <AddUserIcon name="plus" />
    </AddUserIconContainer>
  );
};

const AddUserIcon = styled(Icon)`
  margin: 0;
`;

const UserPickerWrapper = styled.View``;
